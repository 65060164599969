<div class="experience-container" id="experience">
    <h1 class="header-container">
        <img class="experience-image" alt="Experience" src="../../../assets/images/experience-v2.png">
    </h1>
    <div class="content-container">
        <div class="job-container">
            <h2 class="company">ACCENTURE FEDERAL SERVICES</h2>
            <div class="role-container">
                <h3 class="role">Software Engineer Specialist</h3>
                <p class="time-in-role">2019 - Present</p>
            </div>
            <div class="role-container">
                <h3 class="role">Test Engineer Senior Analyst</h3>
                <p class="time-in-role">2018 - 2019</p>
            </div>
            <div class=skill-container>
                <div class="skill-title"><i>Development</i></div>
                <ul>
                    <li>Developed client solutions to maintain and improve three agency-wide web applications utilizing
                        Angular, Node.js,
                        MarkLogic, and AWS Cloud Services.</li>
                    <li>Contributed to the synchronization and communication of our Agile team by adhering to the SAFe
                        Agile principles.</li>
                    <li>Adjusted to the COVID-19 pandemic by developing a new web application through telework on the
                        low-side as the sole
                        developer, which showcased our adaptability to the unique circumstance and our continued focus
                        on providing value to our
                        customer. </li>
                    <li>Advocated to the scrum team stricter adherence to 508 compliance and took the steps necessary
                        through research,
                        documenting standards, training, and developing compliant wireframes and components. This effort
                        is a high priority for
                        our customer and has increased the accessibility and usability of our agency-wide applications
                        for all employees.</li>
                </ul>
            </div>
            <div class=skill-container>
                <div class="skill-title"><i>Test Automation</i></div>
                <ul>
                    <li>Lead the test automation efforts by creating a standardized process, advocating for sprint time dedicated to test automation,
                        providing team training, and providing daily support on technical questions and code reviews.</li>
                    <li>Created and implemented the test automation project utilizing Selenium WebDriver, Cucumber, Protractor, and Chai.</li>
                    <li>Improved test coverage and confidence in code quality by increasing the number of automated scenarios from 0% to 25%.</li>
                </ul>
            </div>
        </div>
        <div class="job-container">
            <h2 class="company">ALIGHT SOLUTIONS (FORMERLY AON HEWITT)</h2>
            <div class="role-container">
                <h3 class="role">Client Manager</h3>
                <p class="time-in-role">2017 - 2018</p>
            </div>
            <div class="role-container">
                <h3 class="role">Client Specialist</h3>
                <p class="time-in-role">2015 - 2017</p>
            </div>
            <div class=skill-container>
                <div class="skill-title"><i>Leadership</i></div>
                <ul>
                    <li>Managed and supported a global team of eight colleagues with daily touch bases to set priorities
                        and monthly training sessions to create and maintain a successful and innovative team. </li>
                    <li>Lead the successful completion of two new development projects with effective communication and
                        documentation.</li>
                </ul>
            </div>
            <div class=skill-container>
                <div class="skill-title"><i>Consulting</i></div>
                <ul>
                    <li>Reduced client involvement in escalated concerns by becoming main point of contact and growing
                        their trust in my ability
                        to resolve their concerns from end to end, which resulted in a positive client relationship
                        leading into contract negotiations.</li>
                    <li>Synchronized Alight teams to ensure that all teams were setting priorities and project schedules
                        that aligned with the
                        client’s goals and expectations.</li>
                </ul>
            </div>
            <div class=skill-container>
                <div class="skill-title"><i>Data Analysis</i></div>
                <ul>
                    <li>Learned SQL independently in order to alleviate the tech team backlog by developing queries that
                        were required for client
                        communication, escalation resolution, and other functional team needs.</li>
                    <li>Developed queries that could evaluate and narrow down large amounts of data to deduce the root
                        cause of a system defect
                        and the total impacted population to present a full analysis report to the client and
                        management.</li>
                    <li>Executed a large-scale annual compliance project that required compiling and reviewing all data
                        for accuracy and annual
                        changes, which resulted in on-time delivery for three consecutive years.</li>
                    <li>Designated as the team’s subject matter expert (SME) for data analysis.</li>
                </ul>
            </div>
        </div>
    </div>
</div>