import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  template: `
        <app-nav></app-nav>
        <app-nav-small></app-nav-small>
        <app-header></app-header>
        <app-experience></app-experience>
        <app-education></app-education>
        <app-certifications></app-certifications>
        <app-skills></app-skills>
        <app-footer></app-footer>`,
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
