<div class="skills-container" id="skills">
    <h1 class="header-container">
        <img alt="Skills" class="skills-image" src="../../../assets/images/skills-v2.png">
    </h1>
    <div class="content-container">
        <div class="flex column">
            <div class="box-container">
                <p>Angular</p>
            </div>
            <div class="box-container">
                <p>Node.js</p>
            </div>
            <div class="box-container">
                <p>Express.js</p>
            </div>
        </div>
        <div class="flex column">
            <div class="box-container">
                <p>Typescript</p>
            </div>
            <div class="box-container">
                <p>Bash Script</p>
            </div>
            <div class="box-container">
                <p>SQL</p>
            </div>
        </div>
        <div class="flex column">
            <div class="box-container">
                <p>MarkLogic</p>
            </div>
            <div class="box-container">
                <p>Selenium</p>
            </div>
            <div class="box-container">
                <p>Python</p>
            </div>
        </div>
    </div>
</div>
