<div id="home" class="header-container">
    <h1>
        <img class="logo-image" alt="Tasha E. Deeter" src="../../../assets/images/name-v2.png">
    </h1>
    <h2 class="text uppercase">Full Stack Developer</h2>
    <h2 class="text uppercase">Denver, CO</h2>
    <div class="button-container">
        <a class="button-primary" title="Contact Me" target="_blank" href="https://www.linkedin.com/in/tasha-deeter-765a2ba7">CONTACT ME</a>
    </div>
</div>
