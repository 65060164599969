import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class AppNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public openModal() {
    
  }
}