<div class="nav-container">
    <a routerLink="" class="home-logo">
        <img alt="Logo" title="Logo" src="../../../assets/images/logo-2.png">
    </a>
    <div class="nav-button uppercase">
        <a title="Experience" routerLink="" fragment="experience">Experience</a>
    </div>
    <div class="nav-button uppercase">
        <a title="Education" routerLink="" fragment="education">Education</a>
    </div>
    <div class="nav-button uppercase">
        <a title="Certifications" routerLink="" fragment="certifications">Certifications</a>
    </div>
    <div class="nav-button uppercase">
        <a title="Skills" routerLink="" fragment="skills">Skills</a>
    </div>
    <div class="header-spacer"></div>
    <div class="right-nav flex">
        <a target="_blank" title="Download Resume" class="button-secondary" href="../../../assets/Web-Deeter-Resume.pdf" download>DOWNLOAD RESUME</a>
    </div>
</div>