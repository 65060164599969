<div class="certifications-container" id="certifications">
    <h1 class="header-container">
        <img class="certification-image" alt="Certifications" src="../../../assets/images/certifications-v2.png">
    </h1>
    <div class="content-container">
        <p>CompTIA Security+</p>
        <p>SAFe Agilist (SA)</p>
        <p>SAFe DevOps Practitioner (SDP)</p>
        <p>CIW JavaScript Certification</p>
    </div>
</div>
