<div class="education-container" id="education">
    <h1 class="header-container">
        <img class="education-image" alt="Education" src="../../../assets/images/education-v2.png">
    </h1>
    <div class="content-container">
        <div class="box-container">
            <h2>COLLEGE OF LIBERAL ARTS, TEXAS A&M UNIVERSITY</h2>
            <p>Bachelor of Arts in International Studies, Major in International
                Politics and Diplomacy</p>
            <P>Bachelor of Arts in Modern Languages, Major in Russian Studies</P>
            <p>Minor in Business Administration</p>
            <h2>MOSCOW STATE UNIVERSITY</h2>
            <p>Study Abroad- Foreign Language Program</p>
            <h2>ST. PETERSBURG POLYTECHNIC STATE UNIVERSITY</h2>
            <p>Study Abroad- Institute of International Educational Programs</p>
        </div>
    </div>
</div>