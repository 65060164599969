<div class="footer-container">
    <a routerLink="" class="home-logo">
        <img alt="Logo" title="Logo" src="../../../assets/images/logo-2.png">
    </a>
    <div class="contact-container">
        <p>Contact Me: </p>
        <a class="linkedIn-link" target="_blank" href="https://www.linkedin.com/in/tasha-deeter-765a2ba7">
            <img alt="LinkedIn" title="LinkedIn" src="../../../assets/images/ln-logo.png">
        </a>
    </div>
</div>